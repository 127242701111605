import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
// core components

import InfoArea from "../../components/InfoArea/InfoArea.js"
import Button from "@material-ui/core/Button"
import styles from "../../jss/intro.js"

import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"

import { DiGoogleAnalytics } from "react-icons/di"
import { DiCodeBadge } from "react-icons/di"

import Filter1Icon from "@material-ui/icons/Filter1"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ padding: "60px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={8} lg={10}>
          <h2 className={classes.title}>Search Engine Optimization (SEO)</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={11} align="left">
          <p className={classes.description}>
            Our team of SEO experts use proven, white hat methods to strengthen
            the online presence of companies. We cover every aspect of SEO
            including keyword research, competitor analysis, backlink building,
            and content marketing. When you hire us, you get full SEO management
            services. Our team handles everything, from metatags and robots.txt
            files to sitemaps.
          </p>
        </Grid>
      </Grid>
      <div style={{ marginTop: 60 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Rank Higher"
              description="A website without any traffic is no better than having no web presence. Our team will help you rank higher on Google to ensure you receive consistent traffic and organic leads to your web page."
              icon={Filter1Icon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Keyword Research"
              description="Having the right keywords is essential for your website. Without the right keywords your web traffic will suffer greatly. We tailor your content in line with what users are searching for."
              icon={DiGoogleAnalytics}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Web Optimization"
              description="Optimizing your website is an extensive process. We handle everything from metatags, robots.txt files and your sitemap. Our web optimization process will ensure your website is formatted effectively for Google’s SEO algorithms. "
              icon={DiCodeBadge}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={4} style={{ padding: "40px" }}>
              <Link
                to="/seo-company-toronto"
                href="https://atlasagency.ca/seo-company-toronto"
              >
                <Button style={{ backgroundColor: "#1f60a6", color: "white", height: "50px",fontSize: "17px" }}>
                  Search Engine Optimization
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
