import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
// @material-ui/icons
import RestoreIcon from "@material-ui/icons/Restore"
import HttpsIcon from "@material-ui/icons/Https"
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly"
// core components

import InfoArea from "../../components/InfoArea/InfoArea.js"
import Button from "@material-ui/core/Button"
import styles from "../../jss/intro.js"
import Contact from "./Contact.jsx"
import { Link } from "gatsby"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} sm={12} md={12} lg={11}>
          <h2 className={classes.title}>Digital Marketing</h2>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            With years of experience, we will modernize and optimize your
            website so that it complements your SEO strategy. With a team of
            experienced SEO experts and web designers in Toronto. Along with
            detailed SEO strategies and implementation, we can help your
            business get the traffic it deserves. Our team of web developers and
            designers have extensive experience in creating user-friendly
            content. Our web design services are:
          </p>
        </Grid>
      </Grid>
      <div style={{ marginTop: 20 }}>
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="More Responsive"
              description="React based websites are more responsive than a traditional Wordpress website. React websites provide consumers with a seamless interaction between different site pages. All web pages will load with no load refresh overhead."
              icon={RestoreIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="More Secure"
              description="All our web designs are HTTPS secured and are extremely secure to ensure your user's information is protected at all times."
              icon={HttpsIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Friendly"
              description="In this new decade, having a mobile friendly web design is immensely beneficial. Google's search engine will rank mobile optimized websites higher than those that aren't."
              icon={MobileFriendlyIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item lg={10} xs={10} sm={6} md={5}>
            <div>
              <iframe
                className={classes.image}
                src="https://www.youtube.com/embed/a3SQc5ILmG8"
                frameBorder="0"
                allow="encrypted-media; gyroscope;"
                allowFullScreen
              />
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={4} lg={12} style={{ position: "relative" ,top: "-40px" }}>
            <Contact />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
