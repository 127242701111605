import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Grid from "@material-ui/core/Grid"

import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"

import WebDesign from "../pages-sections/LandingPage-Sections/WebDesign.jsx"

import SEO from "../pages-sections/LandingPage-Sections/SEO.jsx"

import WebDesignMarketing from "../pages-sections/LandingPage-Sections/WebDesignMarketing.jsx"
import Contact from "../pages-sections/LandingPage-Sections/Contact.jsx"
import SEOMarketing from "../pages-sections/LandingPage-Sections/SEOMarketing.jsx"

import {
  title,
  container,
  whiteColor,
  main,
  mainRaised,
} from "../jss/nextjs-material-kit-pro.js"
// Sections for this page
const useStyles = makeStyles({
  container: {
    ...container,
    zIndex: "10",
    color: "#FFFFFF",
    position: "relative",
  },

  mainRaised: {
    ...main,
    ...mainRaised,
  },
  parallax: {
    height: "70vh",
    "@media screen and (max-width:500px)": {
      height: "60vh",
    },
  },
  title: {
    ...title,
    color: whiteColor + "  !important",
    marginBottom: "25px",
    minHeight: "32px",
    marginLeft: "100px",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      fontSize: "28px",
      marginLeft: "-10px",
      marginTop: "80px",
    },
  },
  container2: {
    "@media screen and (max-width:500px)": {
      padding: "0px 300px 0px 0px",
      fontSize: "28px",
    },

    "@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      marginTop: "240px",
      textAlign: "center",
    },

    "@media screen and (min-width:1100px)": {
      marginTop: "160px",
    },
  },
  blackOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
    paddingTop: "20px",
    color: "white",
  },
})

const getImages = graphql`
  {
    file(relativePath: { eq: "toronto-web-design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default function Home(props) {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Toronto Website Design Company | Website Design Vancouver & Kelowna | Atlas Agency"
        description="Toronto website design company helping business grow online. We provide website design services in Kelowna, Vancouver, and Toronto."
        canonical="https://atlasagency.ca/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />

      <div filter="dark" className={classes.parallax}>
        <div className={classes.blackOverlay}>
          <Grid container justify="center">
            <Grid
              item
              xs={6}
              sm={2}
              md={8}
              lg={10}
              className={classes.container2}
            >
              <h1 style={{ textAlign: "left" }} className={classes.title}>
                Design. Develop. Launch.
              </h1>
            </Grid>

            <Img
              fluid={data.file.childImageSharp.fluid}
              fadeIn={false}
              alt="Toronto-Web-Design"
              loading="eager"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "-1",
              }}
            />
          </Grid>
        </div>
      </div>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        id="main-panel"
      >
        <div className={classes.container}>
          <WebDesign />
          
        </div>
       
        <WebDesignMarketing />
        <div className={classes.container}>
          <SEO />
        </div>
        <SEOMarketing />
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
