import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import App from "../../assets/kelowna-web-design.svg"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"

const getImages = graphql`
  {
    file(relativePath: { eq: "website-designers-toronto.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)

export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)

  return (
    <div>
      <div
        className={classNames(classes.section, classes.sectionDarkMarketing)}
        style={{ paddingBottom: "60px" }}
      >
        <div className={classes.container}>
          <Grid container alignItems="center" justify="center">
             <Grid item lg={5} xs={12} md={6} sm={10}>
              <App
                width="300px"
                height="250px"
                alt="Kelowna-Web-Designer"
              />
            </Grid>
            <Grid item md={8} sm={8} lg={5} xs={10}>
              <div>
                <h3 className={classes.title}>
                  Optimized Web Designs for Lead Generation
                </h3>
                <h4 className={classes.description}>Web Developers</h4>
                <p className={classes.description}>
                  Web design is an important factor of increasing online
                  presence. It has the power to turn random visitors to loyal
                  and paying clients. If a website isn’t appealing to online
                  consumers, 88% of them will turn away and never visit it
                  again. In addition, world of web design is constantly changing
                  and moving into different directions.
                </p>
                <p className={classes.description}>
                  The expectations of consumers evolve on a daily basis. In
                  Toronto, a web designer must keep track of the latest design
                  trends. From using advanced hosting platforms to mobile
                  optimization, a professional website should effectively engage
                  visitors and convert leads into clients.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
