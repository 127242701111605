import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SEO from "../../assets/website-design-toronto.svg"
const getImages = graphql`
  {
    file(relativePath: { eq: "seoMobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)

export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div>
      <div
        className={classNames(classes.section, classes.sectionDarkSEO)}
        style={{ padding: "20px 0px 20px 0px" }}
      >
        <div>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item lg={4} xs={10} md={6} sm={10}>
              <div>
                <SEO
                  alt="seo-company-toronto"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} lg={4} xs={10}>
              <h3 className={classes.title}>Rank Higher on Google</h3>
              <h4 className={classes.description}>SEO Experts</h4>
              <p className={classes.description}>
                Search Engine Optimization, also known as SEO, is an online
                marketing strategy that helps websites rank higher on search
                results. SEO became an important practice when businesses
                started to realize how most online consumers only click on the
                top results.
              </p>
              <p className={classes.description}>
                SEO is a necessary strategy for every type of business.
                Keywords, backlinks, and meta descriptions are becoming a normal
                aspect of running a business. If you want to drive more traffic,
                hiring a SEO expert is the right step.
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
